import {
	Avatar,
	Box,
	Icon,
	Typography,
	useMediaQuery,
	useTheme,
} from '@mui/material';
// import whyShareStyles from './WhyShare.styles';
import loginStyles from '../Login/Login.styles';
import finvuLogo from '../../assets/finvuLogo.png';
import LockIcon from '@mui/icons-material/Lock';

function WhyShare(props) {
	const theme = useTheme();
	const mobileScreen = useMediaQuery(theme.breakpoints.down('md'));

	return (
		<Box sx={loginStyles.footerContainerMobile}>
			<div
				style={{ flexGrow: 1, display: 'flex', gap: 8, alignItems: 'center' }}
			>
				<Avatar
					icon={<LockIcon />}
					variant="square"
					sx={{
						background: 'none',
						height: mobileScreen ? '16px' : '40px',
						width: mobileScreen ? '16px' : '40px',
					}}
				>
					<Icon component={LockIcon} sx={loginStyles.lockIconMobile} />
				</Avatar>
				<Typography
					sx={{
						...loginStyles.footerTextNew,
						color: theme.palette.text,
					}}
				>
					100% secure data sharing via RBI regulated Account Aggregator
				</Typography>
			</div>
			<Avatar
				src={finvuLogo}
				sx={{
					width: mobileScreen ? '48px' : '64px',
					'& .MuiAvatar-img': { objectFit: 'contain' },
				}}
			/>
		</Box>
	);
}

export default WhyShare;
