import { Box, useMediaQuery, useTheme } from '@mui/material';
import { useContext,} from 'react';
import RequestedConsentsHeading from '../Sections/RequestedConsentsHeading';
import './styles.css';


// import required modules
// import { FreeMode, Mousewheel, Pagination } from 'swiper/modules';
import ConsentListContext from '../Context/ConsentContext';

function RequestedConsents(props) {
	const consentContext = useContext(ConsentListContext);
	const consentsAll = consentContext?.consentList
		? consentContext?.consentList
		: [];

	const consentsNum = consentsAll?.length;
	const theme = useTheme();
	const mobileScreen = useMediaQuery(theme.breakpoints.down("md"));
	const isFIU = true


	return (
		<Box
			sx={{
				flex: 1,
				position:  'relative' ,
				top: '1px',
				display: props.display ? props.display : mobileScreen||isFIU ? 'none' : 'flex',
				justifyContent: 'start',
				flexDirection: { xs: 'column', sm: 'column', md: 'column' },
				minHeight: { xs: 'auto', md: `${isFIU ? "auto" : "100vh"}` },
				alignItems: 'start',
				paddingTop: '4.75rem',
				width: '100%',
				maxWidth: `${!isFIU?'fit-content':'none'}`,
				'::after': {
					width: '60%',
					height: '5px',
					background: 'red',
					position: 'absolute',
					bottom: '-5px',
					left: '0',
				},
			}}
		>
			<RequestedConsentsHeading {...props} consentsNum={consentsNum} />
		</Box>
	);
}

export default RequestedConsents;
