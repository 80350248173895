import { Box, Typography, useMediaQuery, useTheme } from '@mui/material';
import React from 'react';
import sizeFontCustom from '../Discovery/fontSize.styles';
import { getConsentTitle } from '../../hepler/functions';
import SelectAll from './SelectAll';

function RequestedConsentsHeading(props) {
	const theme = useTheme();
	const mobileScreen = useMediaQuery(theme.breakpoints.down('md'));
	const subTitle =
		global?.institutionType === 'LSP' ? (
			`Approve to share your account data with ${props?.selectedConsentsState?.length} institutions`
		) : (
			<>
				Approve consent to share your account data with{' '}
				<strong style={{ color: 'black' }}>{global.entityName}</strong>
			</>
		);
	const doesPriorityExist = global?.priorityConsents?.length > 0;
	return (
		<Box
			sx={{
				width: 'inherit',
				padding: {
					xs: '1rem 1rem',
					md: `1rem ${!mobileScreen ? '1rem' : '1.875rem'}`,
				},
			}}
		>
			<Box
				sx={{
					width: '100%',
					display: 'flex',
					alignItems: mobileScreen ? 'flex-start' : 'center',
					flexDirection: mobileScreen ? 'column-reverse' : 'row',
					justifyContent: `${!mobileScreen ? 'space-between' : 'start'}`,
					gap: mobileScreen ? '0.375rem' : 'none',
					marginBottom: mobileScreen ? '0.175rem' : '0.625rem',
				}}
			>
				<Box
					display={'flex'}
					justifyContent={'space-between'}
					width={mobileScreen ? '100%' : 'auto'}
				>
					<Typography
						noWrap
						variant="h6"
						sx={{
							fontSize: { xs: '1rem', md: '1.25rem' },
							mr: '1.23rem',
							display: 'flex',
							alignItems: 'center',
							fontWeight: 900,
							fontFamily: props.customDetails.fontFamily,
						}}
					>
						{getConsentTitle(props.consentsNum)}
					</Typography>
					{mobileScreen && !doesPriorityExist ? <SelectAll {...props} /> : null}
				</Box>

				<Typography
					sx={{
						...sizeFontCustom.subHeading,
						color: theme.palette.black60,
						fontFamily: props.customDetails.fontFamily,
					}}
				>
					{`AA handle: ${global?.userId}`}
				</Typography>
			</Box>
			<Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
				<Typography
					sx={{
						...sizeFontCustom.altHeading,
						textAlign: 'start',
						fontFamily: props.customDetails.fontFamily,
						color: theme.palette.black60,
					}}
				>
					{subTitle}
				</Typography>
				{!mobileScreen && !doesPriorityExist ? <SelectAll {...props} /> : null}
			</Box>
		</Box>
	);
}

export default RequestedConsentsHeading;
