import { Box, Button, ButtonBase, Grid, Link, List, ListItem, ListItemText, Typography, alpha, useTheme } from "@mui/material"
import { useState } from "react";
import styles from "./FipDiscoveredAccountsSection.styles";
import AddNumber from "../AddNumber";
import NotFoundHeading from "./NotFoundHeading";
import FipdNotDiscovered from "./FipdNotDiscovered";
import sizeFontCustom from "./fontSize.styles"

function FipNotDiscoveredAccountsSection(props) {
  const theme = useTheme();
  const fipIds = props?.accountState?.noDiscoveredAccounts?.fips;
  const phoneNumber = props?.accountState?.noDiscoveredAccounts?.phoneNumber
  const [showChangeNumber, setShowChangeNumber] = useState(false)
  const [expandedExplanation, setExpandedExplanation] = useState(false)
  const handleChangeNumber = () => {
    props.setChangingMobileNumber(true)
    setShowChangeNumber(true)
  }
  const toggleAcordion = () => {
    setExpandedExplanation((prev) => !prev);
  };

  return (
    <Grid item
      xs={12}
      sm={8}
      md={8}
      lg={5.5}
    >
      <List sx={{
        background: theme.palette.primary.white,
        justifyContent: "start",
        alignItems: "start",
        padding: "0.5rem",
        borderRadius: "1rem",
      }}>
        <NotFoundHeading />
        {!showChangeNumber &&
          <Box>
            <ListItem sx={{ pt: '0.125rem' }}>
              <ListItemText
                primary={
                  <Box ml={"1rem"}>
                  <Typography
                    sx={sizeFontCustom.subHeading}
                    color={theme.palette.black60}>
                    Could not find accounts for below institutions with mobile number:
                    <span
                      style={{ color: theme.palette.info.main, fontWeight: 700 }}
                    >
                      {" " + phoneNumber + ". "}
                    </span>
                    </Typography>
                  {global.sdkOptions?.allowDifferentMobile === "Y" &&
                  <ButtonBase
                    disabled={props.changingMobileNumber}
                    onClick={handleChangeNumber}
                    sx={ { color: theme.palette.primary.main, cursor: "pointer", textDecoration: "none", fontWeight: "700", textTransform: "capitalize", ...sizeFontCustom.subHeading, mt:".5rem"}}
                  >
                     CHANGE MOBILE  NUMBER.
                  </ButtonBase>}</Box>}
                secondary={
                  <>
                    <Grid
                      container
                      sx={{
                        ...styles.fipNotFoundContainer,
                        '&::-webkit-scrollbar-thumb': {
                          backgroundColor: alpha(theme.palette.primary.main, 0.5),
                          borderRadius: "10px",
                        }
                      }}
                    >
                      {fipIds.map((fipId) => <FipdNotDiscovered key={fipId} fipId={fipId} />)}
                    </Grid>
                    <Box>
                      <Link
                        underline="none"
                        display={!expandedExplanation ? "block" : "none"}
                        onClick={toggleAcordion}
                        sx={{ cursor: "pointer", ...sizeFontCustom.subHeading, mt: "0.8rem" }}
                        color='primary'
                      >
                        More Info
                      </Link>
                      {expandedExplanation &&
                        <>
                          <Typography
                            component="div"
                            py={"0.5rem"}
                            sx={sizeFontCustom.subHeading}
                          >
                            This could be due to either:
                            <ul style={styles.list}>
                              <li>The institution may be experiencing some technical difficulties - please try again later</li>
                              <li>Your account with the bank is a joint account - Banks only support this functionality on Individual accounts</li>
                            </ul>
                          </Typography>
                          <Link
                            underline={'none'}
                            sx={{ cursor: "pointer", ...sizeFontCustom.subHeading }}
                            onClick={toggleAcordion}
                          >
                            Show Less
                          </Link>
                        </>}
                    </Box>
                  </>
                }
              />
            </ListItem>
          </Box>}
        {global.sdkOptions?.allowDifferentMobile === "Y" &&
          (showChangeNumber &&

            <AddNumber {...props} fipSelect={fipIds} setShowChangeNumber={setShowChangeNumber} isComponent={true} />
          )
        }
      </List>
    </Grid>
  )
}

export default FipNotDiscoveredAccountsSection;
